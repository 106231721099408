import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';

import './app.scss';
import BooksPage from "./containers/Books/BooksPage";
import Header from "./components/Header/Header";
import ContactPage from "./containers/ContactPage/ContactPage";
import './components/FontAwesomeIcons/LoadFontAwesomeIcons';
import ProjectsPage from "./containers/Projects/ProjectsPage";
import AboutPage from "./containers/AboutPage/AboutPage";
import NoMatchFourOhFourPage from "./containers/NoMatchFourOhFourPage/NoMatchFourOhFourPage";

class App extends Component {
    render() {
	    ReactGA.initialize('UA-52538190-1');
	    ReactGA.pageview(window.location.pathname + window.location.search);

	    return (
            <div className="App">
                <Header />

                <div className="container content">
                    <Switch>
                        <Route exact path='/' component={AboutPage}/>
                        <Route exact path='/about' component={AboutPage}/>
	                    	<Route exact path='/projects' component={ProjectsPage}/>
                        <Route exact path='/books' component={BooksPage}/>
                        <Route exact path='/contact' component={ContactPage}/>
	                    <Route component={NoMatchFourOhFourPage} />
                    </Switch>
                </div>
                <div className='extraSpace' />
            </div>
        );
    }
}

export default App;
