import React, { Component } from 'react';
import './project.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Project extends Component {
	render() {
		const item = this.props.project;
		const rightarrowclass = !!item.link ? "rightarrow border" : "rightarrow";
		return (
			<a href={item.link} target="_blank" rel="noopener noreferrer" className="projectlink">
				<div className="projectblock">
					<img className="projectimage" src={"./project_images/" + item.icon} alt={item.name + " logo"}/>
					<div className="projectcontents">
						<div className="projectname">{item.name}</div>
						<div className="projectsubname">{item.subname}</div>
						<div className="projectrole">{item.role}</div>
					</div>
					<div className="rightarrowcontainer">
						<div className={rightarrowclass}>
							{this.renderChevron()}
						</div>
					</div>
				</div>
			</a>
		);

	}

	renderChevron() {
		const item = this.props.project;
		return !!item.link
			? ( <div className="flexcta"><div className="cta">{item.cta}</div><FontAwesomeIcon icon="chevron-right"/></div> )
			: null;
	}
}