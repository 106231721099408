import React, { Component } from 'react';
import axios from "axios/index";
import './style.css';

const ReactMarkdown = require('react-markdown');


export default class BooksPage extends Component {
	state = {
		text: "# Loading List..."
	};

	componentDidMount() {
		const url = "https://raw.githubusercontent.com/keitzer/books/master/README.md";

		try {
			var self = this;
			axios.get(url).then(function (response) {
				self.updateText(response);
			}).catch(function (error) {
				console.log(error);
			});
		} catch (e) {
			this.updateText("## Sorry, try refreshing the page.");
		}
	}

	updateText(text) {
		this.setState({
			text: text.data
		});
	}


	render() {
		return (
			<div className="booksContainer">
				<ReactMarkdown children={this.state.text} />
			</div>
		);
	}
}
