import React, { Component } from 'react';
import './contactpage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class ContactPage extends Component {
	render() {
		return (
			<div className="contact-page">
				<h1>Get In Touch</h1>
				<h2>How to contact me</h2>
				<h3>If you're a recruiter</h3>
				<p>
					Don't. Please.
				</p>
				<h3>Otherwise:</h3>
				<p>
					Click the blue <a href="mailto:alex@alexogorek.com"> <FontAwesomeIcon icon='envelope'/>&nbsp; email me</a> button or below to contact me. It's that simple.
				</p>
			</div>
		)
	}
}
