import React, { Component } from 'react';
import './aboutpage.scss';
import NavLink from "react-router-dom/es/NavLink";

export default class AboutPage extends Component {
	render() {
		return (
			<div className="home-page">
				<h1>
					I'm Alex Ogorek
				</h1>
				<div>
					<p>
						I'm the kind of person who says:
					</p>
					<p className="quote">
						I'd rather be busy than bored.
					</p>
					<p>
						To make sure this happens, I have a variety of things that keep me excited every day:
					</p>
					<h2>
						Hobbies
					</h2>
					<p>
						So when I wake up every day, I look forward to reading, working on projects, going running, cooking, and various other hobbies. I'm also interested in topics like psychology, consumer behavior, the science of how things work, and technology.
					</p>
					<h2>
						Projects
					</h2>
					<p>
						When I'm not reading, cooking, or hanging with my wife or dogs,  <NavLink to="/projects">I enjoy working on projects that are fun</NavLink> to use and fun to work on, namely video games. As you can see there, Pixel Diamond Games is my main channel for creating games for the past 10+ years. I love the entire process from designing and creating the game, to publishing and promoting the game and brand.
					</p>
					<p>
						Besides games, I love to work on projects that are easy to use, easy to create, yet extremely helpful in the user's life.
					</p>
					<h2>
						Education
					</h2>
					<p>
						Although I've formally taken classes at University of Akron, University of Southern California, and The Ohio State University, I do not have a degree. I'm a college dropout.
					</p>
					<p>
						And while reading books may not considered formal education, I firmly believe one should always be learning.
						<br/>
						<NavLink to="/books">I strive to read (or listen to) at least 50-100 books per year</NavLink> ranging from self-development and business to machine learning and healthy eating.
					</p>
					<p>
						I've learned a vast majority of my knowledge through books and podcasts, as well as sites like YouTube and StackOverflow and TikTok. I've also learned a lot from friends and exposure to others like me, picking up technologies and ways-of-doing-things that I probably wouldn't have otherwise. If I don't know something, I admit it. I also Google the shit out of it right after to find the answer. Experimenting with everything has made me smarter than any formal education ever could, and I encourage everyone to run experiments on everything in their life.
					</p>
					<h2>
						Corporate
					</h2>
					<div>
						<p>
							Last but not least, here is a list of companies I've worked with. All of these have been as an iOS framework developer, software architect, or software lead. These places, in order of "most recently worked there", are:
						</p>
						<ul>
							<li>Lyft</li>
							<li>AEP (American Electric Power)</li>
							<li>LC (Lifestyle Communities)</li>
							<li>JPMC (Chase)</li>
							<li>Wendy's</li>
							<li>Pillar Technology, consultant at:</li>
								<ul>
									<li>ADS (Alliance Data Systems) and AEP</li>
								</ul>
							<li>FitUnity</li>
							<li>The Cleveland Clinic</li>
							<li>And a myriad of others...</li>
						</ul>
					</div>
				</div>
			</div>
		)
	}
};
