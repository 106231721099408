import React, { Component } from 'react';
import './projectspage.scss';
import Project from "../../components/Project/Project";
import projects from './projects'
import Talk from "../../components/Talk/Talk";

export default class ProjectsPage extends Component {
	render() {
		return (
			<div className="projectspage">
				{ProjectsPage.renderProjects()}
				{ProjectsPage.renderTalks()}

			</div>
		)
	}

	static renderProjects() {
		return projects["projects"].map ((project) => {
			let name = project.name;
			let items = project.items;
			return (
				<div key={project.name} className="projectgroup">
					<h2>{name}</h2>
					<div className="projectlist">
						{items.map ((item) => {
							return <Project project={item} key={item.name + item.subname} />
						})}
					</div>
				</div>
			);
		});
	}

	static renderTalks() {
		let talks = projects["talks"];
		return (
			<div className="projectgroup">
				<h2>Talks</h2>
				<div className="projectlist">
					{talks.map ((talk) => {
						return <Talk talk={talk} key={talk.name + talk.subname} />
					})}
				</div>
			</div>
		);
	}
}