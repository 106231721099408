import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import App from './App';
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";


render(
    <Router>
	    <ScrollToTop>
            <App/>
        </ScrollToTop>
    </Router>, document.getElementById('root'));

