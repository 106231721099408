import React, { Component } from 'react';
import './fourohfour.scss';
import NavLink from "react-router-dom/es/NavLink";

export default class NoMatchFourOhFourPage extends Component {
	render() {
		return (
			<div className="fourohfourheader">
				<p>
					Are you sure you're in the right place? It's okay if you're not. Let me help you out.
				</p>
				<h2>
					Did you mean?
				</h2>
				<div>
					<ul>
						<li>Or maybe you want to <NavLink to="/about">learn about me</NavLink> and my past?</li>
						<li>Did one of <NavLink to="/projects">my projects</NavLink> interest you?</li>
						<li>Or did my <NavLink to="/books">list of books</NavLink> peek your interest?</li>
						<li>When in doubt, you can always try to <NavLink to="/contact">contact me</NavLink> directly.</li>
					</ul>
				</div>
				<h2>
					Or... not?
				</h2>
				<p>
					If it wasn't in that list, I'm sorry, but I'm not sure what you tried to look for.
					It simply isn't here. Again, when in doubt, you can always <NavLink to="/contact">contact me directly</NavLink> and we can sort it out together.
				</p>
			</div>
		);
	}
}
