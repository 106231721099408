import React, { Component } from 'react';

import './header.scss';
import NavLinkList from "../NavLinkList/NavLinkList";
import Sticky from 'react-sticky-el';

export default class Header extends Component {
	render() {

		return (
			<div className="header thin">
				<div className="topLevelContainer">
					<img className="bannerImage" src={"/img12.jpg"} alt="Alex Ogorek Product Code Innovation"/>
				</div>
				<div className="desktopheader">
					<Sticky topOffset={0} stickyClassName="navListSticky">
						<NavLinkList/>
					</Sticky>
				</div>
				<div className="mobileheader">
					<NavLinkList/>
				</div>
			</div>
		);
	}
}

