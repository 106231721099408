import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import './style.css';

export default class NavLinkList extends Component {

	render() {
		return (
			<div className="thin navlinklist">
				<div className="container">
					<NavLink to="/">About</NavLink>
					<NavLink to="/projects">My Projects, Books, &amp; Talks</NavLink>
					<NavLink to="/books">Reading List</NavLink>
					<NavLink to="/contact">Contact</NavLink>
				</div>
			</div>
		);
	}
}
