import React, { Component } from 'react';
import './talk.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Talk extends Component {
	render() {
		const item = this.props.talk;
		const talkicon = item.type === "youtube" ? ['fab', 'youtube'] : "podcast";
		const talkiconclass = item.type === "youtube" ? "talkicon red" : "talkicon purple";
		return (
			<a href={item.link} target="_blank" rel="noopener noreferrer" className="talklink">
				<div className="talkblock">
					<div className={talkiconclass}><FontAwesomeIcon icon={talkicon}/></div>
					<div className="talkcontents">
						<div className="talkname">{item.name}</div>
						<div className="talkdate">{item.date}</div>
					</div>
					<div className="rightarrow">
						<FontAwesomeIcon icon="chevron-right"/>
					</div>
				</div>
			</a>
		);
	}
}